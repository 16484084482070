<template>
  <div class="service">
    <ul class="ser_list clear">
      <li>
        <img src="../assets/img/indexImg/hotSpot.png" alt="">
        <div>
          <p class="title">应用软件开发(商城)</p>
          <div class="describe">总体架构开发、测试、交互 、后期运维等服务 </div>
        </div>
      </li>
      <li>
        <img src="../assets/img/indexImg/hotSpot.png" alt="">
        <div>
          <p class="title">应用软件开发(商城)</p>
          <div class="describe">总体架构开发、测试、交互 、后期运维等服务 </div>
        </div>
      </li>
      <li>
        <img src="../assets/img/indexImg/hotSpot.png" alt="">
        <div>
          <p class="title">应用软件开发(商城)</p>
          <div class="describe">总体架构开发、测试、交互 、后期运维等服务 </div>
        </div>
      </li>
      <li>
        <img src="../assets/img/indexImg/hotSpot.png" alt="">
        <div>
          <p class="title">应用软件开发(商城)</p>
          <div class="describe">总体架构开发、测试、交互 、后期运维等服务 </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "service"
}
</script>

<style scoped>
@import url('../assets/css/service.css');
</style>